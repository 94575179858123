.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Adjust layout for mobile screens */
@media (max-width: 991px) {
  .navbar {
    padding: 12px 10px !important; /* Reduce horizontal padding on smaller screens */
  }

  .navbar .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-brand {
    margin-left: 0;
    margin-right: 0; /* Remove margin for proper alignment */
  }

  .navbar-brand img {
    width: 150px; /* Reduce the width for smaller screens */
    height: auto; /* Maintain aspect ratio */
    margin-right: 0;
  }

  .navbar-toggler {
    margin-left: auto; /* Keep the hamburger menu on the right of the logo */
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ensure dropdown items are black by default */
.dropdown-menu .dropdown-item {
  color: black !important;  
  background-color: transparent !important;
  transition: all 0.2s ease-in-out; /* Smooth transition */
}

/* Ensure proper hover effect */
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0.1) !important; /* Light gray hover */
  color: black !important; /* Keep text black */
}

/* Optional: Improve dropdown menu styling */
.dropdown-menu {
  padding: 8px 0;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adds slight shadow */
  background-color: white !important; /* Ensure background is white */
}

/* Fix Bootstrap overriding hover styles */
.navbar .dropdown-menu {
  display: none;  /* Hide dropdown by default */
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.navbar .nav-item.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
}

.pagination-container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap instead of overflowing */
  justify-content: center;
  max-width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling if needed */
  padding: 10px;
}

.pagination {
  flex-wrap: wrap; /* Ensures pagination items wrap to the next line */
  display: flex;
  gap: 5px;
}

.pagination-item {
  min-width: 35px;
  text-align: center;
}

