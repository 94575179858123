
body {
    margin: 0;
    font-family: monospace, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-size: 48px; */
  }
  
  code {
    font-family: monospace, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  